<template>
  <div class="shell-box">
    <el-breadcrumb separator="/" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/myHome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{
          path: `/routerPolicyManagement/${this.$route.meta.policyRoute}/policyManagement`,
        }"
        >保单列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>保单编辑</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="form">
      <policyForm
        ref="policyForm"
        :detailData="policyDetail"
        :permissions="policyDetailPermissions"
      />
    </div>
    <div class="operating-box">
      <el-button size="mini" @click="handleBack">返回</el-button>
      <el-button size="mini" type="primary" @click="handleReset" plain
        >重置</el-button
      >
      <!-- <el-button
        size="mini"
        type="warning"
        plain
        @click="showUpload = true"
        v-if="$route.meta.policyType == 1"
        >上传附件</el-button
      > -->
      <el-button size="mini" type="primary" @click="handlePolicyUpdate"
        >提交</el-button
      >
    </div>
    <DragMultipleUpload
      :show.sync="showUpload"
      :dataEcho="dataEcho"
      :fileSzie="10"
      :canDeletOld="false"
      @submitData="handleSubmitAttachment"
    ></DragMultipleUpload>
  </div>
</template>

<script>
import policyForm from "@/views/policyManagement/components/form.vue";
import { getPolicyDetail, policyUpdate } from "@/api/policy";
import DragMultipleUpload from "@/components/DragMultipleUpload";
import { hasPerms, dataPermissions } from "@/utils/auth";
export default {
  components: { policyForm, DragMultipleUpload },
  data() {
    return {
      policyDetail: {},
      originalPolicyDetail: null,
      showUpload: false,
      dataEcho: {},
      attachmentData: {},
      policyDetailPermissions: {
        agentName: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "agentName"
        ),
        // "desc": "代理人", +
        applicantMode: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "applicantMode"
        ),
        // "desc": "投保方式", +
        lastPolicyNo: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "lastPolicyNo"
        ),
        // "desc": "上一年保单号", +
        endDate: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "endDate"
        ),
        // "desc": "到期时间", +
        city: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "city"
        ),
        // "desc": "所属地区", +
        policyNo: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "policyNo"
        ),
        // "desc": "保单号", +
        province: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "province"
        ),
        // "desc": "所属省份", +
        applicantType: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "applicantType"
        ),
        // "desc": "投保类型", +
        acceptCompanyList: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "acceptCompanyList"
        ),
        // "desc": "承保公司", +
        extensionClause: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "extensionClause"
        ),
        // "desc": "免费扩展条款",+
        specialAgreement: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "specialAgreement"
        ),
        // "desc": "特别约定",+
        applicantStore: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "applicantStore"
        ),
        // "desc": "投保门店",+
        absoluteDeductible3: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "absoluteDeductible3"
        ),
        // "desc": "未扩展不计免赔",+
        absoluteDeductible2: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "absoluteDeductible2"
        ),
        // "desc": "扩展不计免赔2",+
        salesmanId: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "salesmanName"
        ),
        // "desc": "业务员",+
        agentNo: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "agentNo"
        ),
        // "desc": "代理人编码",+
        beneficiaryList: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "beneficiaryList"
        ),
        // "desc": "受益人",+
        policyHolderList: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "policyHolderList"
        ),
        // "desc": "投保人",+
        totalPremium: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "totalPremium"
        ),
        // "desc": "总保费",+
        ep: dataPermissions(`M20_${this.$route.meta.policyType}-Edit`, "ep"),
        // "desc": "电子保单",+
        projectRemark: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "projectRemark"
        ),
        // "desc": "项目备注",+
        policyPlanList: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "policyPlanList"
        ),
        // "desc": "保障计划",+
        policySubjectList: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "policySubjectList"
        ),
        // "desc": "标的信息",+
        absoluteDeductible: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "absoluteDeductible"
        ),
        // "desc": "扩展不计免赔1",+
        policyInsuredList: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "insured"
        ),
        // "desc": "被保险人",+
        projectName: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "projectName"
        ),
        // "desc": "项目名称",+
        startDate: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "startDate"
        ),
        // "desc": "起保时间",+
        enquiryNo: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "enquiryNo"
        ),
        // "desc": "询价单号",+
        recorder: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "recorder"
        ),
        // "desc": "录单员",
        specialVersion: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "specialVersion"
        ),
        // "desc": "特别说明",
        bindUser: dataPermissions(
          `M20_${this.$route.meta.policyType}-Edit`,
          "bindUser"
        ),
        // 绑定客户
        insuranceOfDate: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "insuranceOfDate"
        ),
        // 投保日期
        sourceChannel: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "sourceChannel"
        ),
        // 业务渠道
        upstreamAgent: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "upstreamAgent"
        ),
        // 业务代理人
        // 险种类型
        insuranceTypeName: dataPermissions(
          `M20_${this.$route.meta.policyType}-View`,
          "insuranceTypeName"
        ),
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      getPolicyDetail({
        id: this.$route.params.id,
      })
        .then((res) => {
          this.policyDetail = res.data;
          this.originalPolicyDetail = JSON.parse(JSON.stringify(res.data));
          let dataBackShow = [];
          let idInd = 1;
          res.data.attachments
            ? Object.keys(res.data.attachments).forEach(function (key) {
                let type = (key + "").split("-");
                dataBackShow.push({
                  type: type[0] == "qt" ? undefined : type[0],
                  selectValue: Number(type[1] || 0),
                  urls: res.data.attachments[key].map((val) => {
                    idInd++;
                    return {
                      suffix: val.fileType,
                      name: val.fileName,
                      url: val.url,
                      id: idInd,
                    };
                  }),
                });
              })
            : res.data.attachments = {
          kpxx: [],
          qtxx: [],
          zjxx: [],
        };
          this.dataEcho = dataBackShow;
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleBack() {
      this.$router.go(-1);
    },
    async handlePolicyUpdate() {
      let data = await this.$refs.policyForm.handleSubmit();
      data = this.handleSequence(data, [
        "beneficiaryList",
        "policyHolderList",
        "policyInsuredList",
      ]);
      if (this.$route.meta.policyType == 3) {
        delete data.policySubjectList;
      }
      if (this.$route.meta.policyType != 1) {
        data.attachmentType = "zjxx,qtxx,yyzz,kpxx";
      } else if (this.attachmentData.attachments) {
        let attachmentType = [];
        for (const key in this.attachmentData.attachments) {
          attachmentType.push(key);
        }
        data.attachmentType = attachmentType.toString();
      } else {
        data.attachmentType = "";
      }
      policyUpdate({ ...data, ...this.attachmentData }).then((res) => {
        this.handleBack();
        this.$message.success("编辑成功");
      });
    },
    handleReset() {
      this.attachmentData = {};
      this.policyDetail = JSON.parse(JSON.stringify(this.originalPolicyDetail));
      let dataBackShow = {};
      let idInd = 1;
      for (const key in this.policyDetail.attachments) {
        let type = (key + "").split("-");
        dataBackShow[type[0]] = {
          type: type[0] == "qt" ? undefined : type[0],
          selectValue: Number(type[1]),
          urls: this.policyDetail.attachments[key].map((val) => {
            idInd++;
            return {
              suffix: val.fileType,
              name: val.fileName,
              url: val.url,
              id: idInd,
            };
          }),
        };
      }
      this.dataEcho = dataBackShow;
      this.$forceUpdate();
    },
    handleSubmitAttachment(e) {
      let attachment = {};
      let attachmentType = [];
      let qtInd = 1;
      e.map((val) => {
        if (val.type) {
          attachmentType.push(val.type + "-" + val.selectValue);
          attachment[val.type + "-" + val.selectValue] = val.urls.map(
            (item) => {
              return {
                attachmentType: val.type + "-" + val.selectValue,
                fileName: item.name,
                fileType: item.suffix,
                url: item.url,
              };
            }
          );
        } else {
          attachmentType.push("qt" + qtInd + "-" + val.selectValue);
          attachment["qt" + qtInd + "-" + val.selectValue] = val.urls.map(
            (item) => {
              return {
                attachmentType: val.type + "-" + val.selectValue,
                fileName: item.name,
                fileType: item.suffix,
                url: item.url,
              };
            }
          );
          qtInd++;
        }
      });
      this.attachmentData = {
        attachments: attachment,
        attachmentType: attachmentType.toString(),
      };
      this.showUpload = false;
    },
    handleSequence(obj = {}, list = [], sort = "sort") {
      if (list && list.length) {
        list.forEach((item) => {
          if (obj[item].length) {
            obj[item].forEach((p, i) => {
              p[sort] = i;
            });
          }
        });
      }
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  .form {
    flex: 1;
    overflow: hidden;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operating-box {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 196px);
    height: 50px;
    padding: 0 24px;
    background: #ffffff;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    z-index: 99;
  }
}
</style>
